import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Locale, Translation } from '../../../translations/models.translations';

@Pipe({
  name: 'translation',
  standalone: true
})
export class TranslationPipe implements PipeTransform {

  constructor(@Inject('LOCALE') public locale: Locale) {
  }
  transform(value: Translation): string {
    return value[this.locale] || '';
  }

}
